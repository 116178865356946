<template>
  <v-container max-width="70vw">
    <!-- Form to Create Activity Type -->
    <v-card class="mb-5">
      <v-card-title>Create Activity Type</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createActivityType">
          <v-text-field
            v-model="form.name"
            label="Activity Type Name"
            required
          ></v-text-field>
          <v-switch
            color="secondary"
            v-model="form.chargeable"
            label="Chargeable"
            inset
          ></v-switch>
          <v-text-field
            v-model="form.priority"
            type="number"
            label="Priority"
          ></v-text-field>
          <v-switch
            v-model="form.visible"
            label="Visible"
            inset
            color="secondary"
          ></v-switch>
          <v-switch
            color="secondary"
            v-model="form.customer_required"
            label="Customer Required"
            inset
          ></v-switch>
          <v-switch
            color="secondary"
            v-model="form.location_required"
            label="Location Required"
            inset
          ></v-switch>
          <v-switch
            color="secondary"
            v-model="form.reference_one_required"
            label="Reference One Required"
            inset
          ></v-switch>

          <v-btn type="submit" color="primary" :loading="loadingState.isLoading"
            >Create</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Data Table for Activity Types -->
    <v-card>
      <v-card-title>Activity Types</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="activities">
          <template #[`item.actions`]="{ item }">
            <v-btn icon="mdi-pencil" outlined @click="openUpdateDialog(item)" />
            <v-btn icon="mdi-delete" @click="deleteActivityType(item.id)" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Update Activity Type Dialog -->
    <v-dialog v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title>Update Activity Type</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateActivityType">
            <v-text-field
              v-model="form.name"
              label="Activity Type Name"
              required
            ></v-text-field>
            <v-switch
              v-model="form.chargeable"
              label="Chargeable"
              inset
              color="secondary"
            ></v-switch>
            <v-text-field
              v-model="form.priority"
              type="number"
              label="Priority"
              color="secondary"
            ></v-text-field>
            <v-switch
              v-model="form.visible"
              label="Visible"
              inset
              color="secondary"
            ></v-switch>
            <v-switch
              v-model="form.customer_required"
              label="Customer Required"
              inset
              color="secondary"
            ></v-switch>
            <v-switch
              v-model="form.location_required"
              label="Location Required"
              inset
              color="secondary"
            ></v-switch>
            <v-switch
              v-model="form.reference_one_required"
              label="Reference One Required"
              inset
              color="secondary"
            ></v-switch>

            <v-btn
              type="submit"
              color="primary"
              :loading="loadingState.isLoading"
              >Update</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
     <!-- Snackbar for Notifications -->
     <v-snackbar
        v-model="snackbar.show"
        :timeout="5000"
        :color="snackbar.color"
        top
      >{{ snackbar.message }}
      </v-snackbar>
  </v-container>
</template>

<script>
import internalApi from "../apis/internal";
import { getHeaders } from "../helpers/commonFunctions";
import { useLoadingState } from "../store/loadingState";

export default {
  name: "ActivityTypePage",

  // Define the data function for local state
  data() {
    return {
      form: {
        name: "",
        chargeable: false,
        priority: null,
        visible: false,
        customer_required: false,
        location_required: false,
        reference_one_required: false,
      },
      snackbar: {
          show: false,
          message: '',
          color: '',
        },
      activities: [], // Holds the list of activity types
      loading: false, // Loading state for the form
      headers: [],
      endpoint: "ActivityTypes",
      updateDialog: false, // Control the visibility of the update dialog
      currentActivityId: null, // ID of the current activity to update
      loadingState: useLoadingState(),
    };
  },

  // The created lifecycle hook to load activities when the component is created
  created() {
    this.getActivityTypes();
  },

  methods: {
    // Fetch all activity types
    async getActivityTypes() {
      try {
        const response = await internalApi.genericGet(this.endpoint);
        this.activities = response;
        this.headers = getHeaders(response, [], true);
      } catch {
        this.showSnackbar("error loading activity types", "error");
      }
    },

    // Handle form submission to create a new activity type
    async createActivityType() {
      try {
        this.loading = true;
        await internalApi.genericPost(this.endpoint, this.form);
        this.getActivityTypes();
        this.resetForm(); // Reset the form after submission
        this.showSnackbar(`activity type created`, 'success');
      } catch {
        this.showSnackbar("error creating activity type", "error");
      }
    },

    // Open the update dialog and populate the form
    openUpdateDialog(item) {
      this.currentActivityId = item.id; // Store the ID of the activity to be updated
      this.form = { ...item }; // Populate the form with the current item data
      this.updateDialog = true; // Show the dialog
    },

    // Update the activity type
    async updateActivityType() {
      try {
        this.loading = true;
        await internalApi.genericPut(
          this.endpoint,
          this.currentActivityId,
          this.form
        );
        // Update the activity in the local array
        const index = this.activities.findIndex(
          (activity) => activity.id === this.currentActivityId
        );
        if (index !== -1) {
          this.activities[index] = { ...this.form, id: this.currentActivityId }; // Update the activity
        }
        this.resetForm(); // Reset the form after submission
        this.updateDialog = false; // Close the dialog
        this.showSnackbar(`activity type updated`, 'success');
      } catch {
        this.showSnackbar("error updating activity type", "error");
      }
    },

    // Delete an activity type by ID
    async deleteActivityType(id) {
      try {
        await internalApi.genericDelete(this.endpoint, id);
        this.activities = this.activities.filter(
          (activity) => activity.id !== id
        );
        this.showSnackbar(`activity type deleted`, 'success');
      } catch {
        this.showSnackbar("error deleting activity type", "error");
      }
    },
    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    // Reset the form to its default state
    resetForm() {
      this.form = {
        name: "",
        chargeable: false,
        priority: null,
        visible: false,
        customer_required: false,
        location_required: false,
        reference_one_required: false,
      };
      this.currentActivityId = null; // Reset the current activity ID
    },
  },
};
</script>

<style scoped>
</style>
