<template>
  <v-container max-width="70vw">
    <!-- Form to Create Team -->
    <v-card class="mb-5">
      <v-card-title>Create Team</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createTeam">
          <v-text-field
            v-model="form.name"
            label="Team Name"
            required
          ></v-text-field>
          <v-btn
            type="submit"
            color="primary"
            :loading="loadingState.isLoading"
            :disabled="!canCreateTeam"
          >
            Create
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Data Table for Teams -->
    <v-card class="mb-5">
      <v-card-title>Teams</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="teamHeaders"
          :items="teams"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-btn
              icon="mdi-delete"
              variant="outlined"
              @click="deleteTeam(item.id)"
              :loading="loadingState.isLoading"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Select Existing Role -->
    <v-card class="mb-5">
      <v-card-title>Select Team</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedTeamId"
          :items="teams"
          item-title="name"
          item-value="id"
          label="Select Team"
          @update:modelValue="loadTeamDetails"
        ></v-select>
      </v-card-text>
    </v-card>

    <!-- Conditionally Show Role Menus -->
    <v-card v-if="selectedTeamId" class="pa-4">
      <v-card-title>{{ teamDetails ? teamDetails.name : "" }}</v-card-title>
      <v-row>
        <v-col cols="12">
          <!-- Add Operators -->
          <v-card class="mb-3 pa-2">
            <v-card-title>Add Operator</v-card-title>
            <v-select
              v-model="selectedOperator"
              :items="filteredTeamOperators"
              item-title="operator_name"
              item-value="id"
              label="Select Operator"
            ></v-select>
            <v-btn
              @click="addTeamOperator"
              :disabled="!selectedOperator"
              color="primary"
              :loading="loadingState.isLoading"
            >
              Add Operator
            </v-btn>

            <v-divider class="my-3 mx-0 px-0" />
            <!-- team operators table -->
            <v-data-table
              v-if="teamDetails && teamDetails.team_operators.length"
              :headers="teamOperatorHeaders"
              :items="teamDetails.team_operators"
              
            >
              <template #[`item.supervisor`]="{ item }">
                <v-switch
                  v-model="item.supervisor"
                  label=""
                  inset
                  color="secondary"
                  @input="
                    updateTeamOperatorField(
                      item.id,
                      'supervisor',
                      item.supervisor
                    )
                  "
                ></v-switch>
              </template>
              
              
              <template #[`item.operator_id`]="{ item }">
                <p>{{ getOperatorName(item.operator_id) }}</p>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-btn
                  icon="mdi-delete"
                  variant="outlined"
                  :loading="loadingState.isLoading"
                  @click="deleteTeamOperator(item.id)"
                />
              </template>
            </v-data-table>

            <p v-else class="pa-2">This team does not have any operators</p>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

 

    <!-- Snackbar for Notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      top
      >{{ snackbar.message }}</v-snackbar
    >
  </v-container>
</template>
  
  <script>
import internalApi from "../apis/internal";
import { useLoadingState } from "../store/loadingState";
import { getHeaders } from "../helpers/commonFunctions";

export default {
  name: "TeamsPage",
  data() {
    return {
      form: {
        role_name: "",
      },
      search: "",
      teams: [],
      teamHeaders: [],
      operators: [],
      selectedTeamId: null,
      selectedOperator: null,
      teamDetails: null,

      teamOperatorHeaders: [
        { title: "Operator", value: "operator_id", sortable: true },
        { title: "Supervisor", value: "supervisor", sortable: true },
        { title: "", value: "actions" },
      ],

      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      loadingState: useLoadingState(),
   
    };
  },
  computed: {
    // Filter out activity types that have already been added for the role
    filteredTeamOperators() {
      return this.operators.filter( op => op.team_operators.length < 1)
   
    },
    canCreateTeam() {
      return this.form.name;
    },
  },
  created() {
    this.getTeams();
    this.getOperators();
  },
  methods: {
    getOperatorName(operatorId){
      const found = this.operators.find(op => op.id === operatorId)
      return found ? found.operator_name : operatorId
    },
    async deleteTeamOperator(id) {
      try {
        await internalApi.genericDelete("/TeamOperators", id);
        this.teamDetails.team_operators =
          this.teamDetails.team_operators.filter((row) => row.id !== id);
        this.showSnackbar("Team operator deleted");
      } catch (error) {
        this.showSnackbar("Error deleting entry" + error);
      }
    },
    async updateTeamOperatorField(id, key, value) {
      try {
        await internalApi.genericPut(`TeamOperators`, id, {
          [key]: value,
        });
        this.showSnackbar(`field updated`, "success");
      } catch (error) {
        this.showSnackbar(`Error updating team`, "error");
      }
    },
    async deleteTeam(id) {
      try {
        await internalApi.genericDelete("Teams", id);
        this.teams = this.teams.filter((role) => role.id !== id);
        this.showSnackbar(`Team deleted`, "success");
      } catch (error) {
        this.showSnackbar("Error deleting team", "error");
      }
    },
    // Fetch all teams
    async getTeams() {
      try {
        const response = await internalApi.genericGet("Teams");
        this.teams = response;
        this.teamHeaders = getHeaders(response, [], true); // Adjust headers as necessary
      } catch (error) {
        this.showSnackbar("Error fetching teams", "error");
      }
    },
    // Fetch all activity types
    async getOperators() {
      try {
        const operators = await internalApi.genericGet("Operators",  {
            associations: "team_operators",
          });
        this.operators = operators
      } catch (error) {
        this.showSnackbar("Error fetching activity types", "error");
      }
    },
   
    // Load role details with activity types
    async loadTeamDetails() {
      try {
        const response = await internalApi.genericGet(
          `Teams/${this.selectedTeamId}`,
          {
            associations: "team_operators",
          }
        );
        const teamDetails = response;
        this.teamDetails = teamDetails;
      } catch (error) {
        this.showSnackbar("Error fetching role details", "error");
      }
    },
    // Create Role
    async createTeam() {
      try {
        if (!this.canCreateTeam) return;

        // Step 1: Create the role
        await internalApi.genericPost("Teams", {
          name: this.form.name,
        });

        // Reset form
        this.getTeams();
        this.resetForm();
        this.showSnackbar("Team created successfully", "success");
      } catch (error) {
        this.showSnackbar("Error creating team", "error");
      }
    },
    // Add Operator to Team
    async addTeamOperator() {
      try {
        await internalApi.genericPost("TeamOperators", {
          team_id: this.selectedTeamId,
          operator_id: this.selectedOperator,
          supervisor: false,
        });

        this.loadTeamDetails(); 
        this.selectedOperator = null; 
        this.showSnackbar("Operator added successfully", "success");
      } catch (error) {
        this.showSnackbar("Error adding operator", "error");
      }
    },
    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    // Reset the form to its default state
    resetForm() {
      this.form = {
        name: "",
      };
    },
  },
};
</script>
  
  <style scoped>
</style>
  