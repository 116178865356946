<template>
  <v-container max-width="97vw">
    <!-- Open Activities Report -->
    <v-card class="mb-5">
      <div class="d-flex">
        <v-card-title>Open Activities By User </v-card-title> <v-spacer />
        <v-btn
          icon="mdi-refresh"
          variant="text"
          @click="getOpenActivitiesByUser"
          class="pa-2"
        />
      </div>
      <v-card class="pa-4 ma-2" variant="outlined">
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="selectedRole"
              :clearable="true"
              :items="roles"
              item-title="name"
              item-value="id"
              label="role (optional)"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="location"
              :clearable="true"
              label="location (optional)"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="selectedOperator"
              :clearable="true"
              :items="operators"
              item-title="operator_name"
              item-value="id"
              label="operator (optional)"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn
              @click="getOpenActivitiesByUser()"
              color="primary"
              :loading="loadingState.isLoading"
              >load report</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <v-card-text>
        <!-- Data Table for Open Activities By User -->
        <v-data-table
          v-if="openActivities"
          :headers="openActivitiesHeaders"
          :items="openActivities"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              variant="outlined"
              :loading="loadingState.isLoading"
              @click="
                triggerCloseActivityDialog(
                  item.activity_log_id,
                  item.operator_name,
                  item.activity_start
                )
              "
              >close open activity</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- close activity dialog -->
    <v-dialog v-model="closeActivityDialog" max-width="800px">
      <v-card class="pa-4">
        <v-card-title
          >Close open activity for {{ closeForm.operator_name }} - Started at
          {{ closeForm.activity_start }}</v-card-title
        >
        <v-form @submit.prevent="closeOpenActivity">
          <v-text-field
            v-model="closeForm.activity_finish"
            label="Time of closing activity"
            type="datetime-local"
            required
          />
          <v-btn
            :disabled="!canCloseActivity"
            type="submit"
            color="primary"
            :loading="loadingState.isLoading"
            >Close Open Activity</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Log Output Report -->
    <v-card class="mb-5">
      <div class="d-flex">
        <v-card-title>Activity Log Output Report </v-card-title> <v-spacer />
        <v-btn
          icon="mdi-refresh"
          variant="text"
          @click="getLogOutputReport"
          class="pa-2"
        />
      </div>
      <v-card class="pa-4 ma-2" variant="outlined">
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="startDate"
              label="start date"
              type="date"
              required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="endDate"
              label="end date"
              type="date"
              required
            />
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="selectedOperator2"
              :clearable="true"
              :items="operators"
              item-title="operator_name"
              item-value="id"
              label="operator (optional)"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="!startDate || !endDate"
              @click="getLogOutputReport()"
              color="primary"
              :loading="loadingState.isLoading"
              >load report</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <v-card-text>
        <!-- Data Table  -->
        <v-data-table
          v-if="outputReport"
          :headers="outputReportHeaders"
          :items="outputReport"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <div class="d-flex">
              <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
              dense
            ></v-text-field>
            <ExportButtons :data="outputReport" :headers="outputReportHeaders" 
            :title="'activity_log_output_report'" />
            </div>
            
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Snackbar for Notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      top
      >{{ snackbar.message }}</v-snackbar
    >
  </v-container>
</template>
  
  <script>
import internalApi from "../apis/internal";
import { useLoadingState } from "../store/loadingState";
import { getHeaders, getTodayDateStr } from "../helpers/commonFunctions";
import { authStore } from "../store/authStore";
import ExportButtons from "../components/ExportButtons.vue";
export default {
  name: "ReportsPage",
  components: { ExportButtons },
  data() {
    return {
      search: "",
      openActivities: null,
      openActivitiesHeaders: [],
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      roles: [],
      selectedRole: null,
      operators: [],
      selectedOperator: null,
      location: null,
      loadingState: useLoadingState(),
      closeForm: {
        id: null,
        activity_finish: null,
        operator_name: null,
        activity_start: null,
      },
      closeActivityDialog: false,
      outputReport: null,
      outputReportHeaders: [],
      startDate: null,
      endDate: null,
      selectedOperator2: null,
    };
  },
  computed: {
    canCloseActivity() {
      return this.closeForm.id && this.closeForm.activity_finish;
    },
  },
  created() {
    // this.getOpenActivitiesByUser();
    this.getRoles();
    this.getOperators();
    this.startDate = this.endDate = getTodayDateStr()
    
  },
  methods: {
    convertToIsoFormat(input) {
      // Split the input into date and time parts
      const [datePart, timePart] = input.split(" ");
      const [day, month, year] = datePart.split("/");
      const [hours, minutes] = timePart.split(":");

      // Manually format the result as "YYYY-MM-DDTHH:mm"
      return `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}T${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
    },
    resetForm() {
      this.closeActivityDialog = false;
      this.closeForm = {
        id: null,
        activity_finish: null,
        operator_name: null,
        activity_start: null,
      };
    },
    async closeOpenActivity() {
      try {
        if (!this.canCloseActivity) return;
        await internalApi.genericPost("activities/close-activity", {
          id: this.closeForm.id,
          activity_finish: this.closeForm.activity_finish,
        });
        this.resetForm();
        this.getOpenActivitiesByUser();
        this.showSnackbar("activity closed", "success");
      } catch (error) {
        this.showSnackbar("Error closing activity", "error");
      }
    },
    triggerCloseActivityDialog(id, operator_name, activity_start) {
      this.closeForm.id = id;
      this.closeForm.operator_name = operator_name;
      this.closeForm.activity_finish = this.convertToIsoFormat(activity_start);
      this.closeForm.activity_start = activity_start;
      this.closeActivityDialog = true;
    },
    async getOperators() {
      try {
        const response = await internalApi.genericGet("Operators", {
          associations: "team_operators",
        });
        if (!authStore.operatorRoles.includes("admin")) {
          const teamId = authStore.auth0Operator.team_operators[0].team_id;
          this.operators = response.filter(
            (op) =>
              op.team_operators.length &&
              op.team_operators[0].team_id === teamId
          );
        } else {
          this.operators = response;
        }
      } catch (error) {
        this.showSnackbar("Error loading operators", "error");
      }
    },
    async getRoles() {
      try {
        const response = await internalApi.genericGet("Roles");
        this.roles = response;
      } catch (error) {
        this.showSnackbar("Error fetching roles", "error");
      }
    },
  
    async getOpenActivitiesByUser() {
      try {
        let params = {};
        if (this.selectedRole) {
          params.role_id = this.selectedRole;
        }
        if (this.location) {
          params.location = this.location;
        }
        if (this.selectedOperator) {
          params.operator_id = this.selectedOperator;
        }
        const response = await internalApi.genericGet(
          "activities/open-activities-by-user",
          params
        );
        this.openActivities = response;
        this.openActivitiesHeaders = getHeaders(
          response,
          ["operator_id", "role_id", "team_id"],
          true
        ); // Adjust headers as necessary
      } catch (error) {
        this.showSnackbar("Error loading open activities report", "error");
      }
    },
    async getLogOutputReport() {
      try {
        if (!this.startDate || !this.endDate) return;
        let params = {
          start_date: this.startDate,
          end_date: this.endDate,
        };

        if (this.selectedOperator2) {
          params.operator_id = this.selectedOperator2;
        }
        const response = await internalApi.genericGet(
          "activities/log-output-report",
          params
        );
        this.outputReport = response;
        this.outputReportHeaders = getHeaders(response, false, true); // Adjust headers as necessary
      } catch (error) {
        this.showSnackbar("Error loading output report", "error");
      }
    },

    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    // Reset the form to its default state
  },
};
</script>
  
  <style scoped>
</style>
  