<template>
  <div>
    <!-- Show the login button if the user is not authenticated -->
    <v-btn
      v-if="!isAuthenticated"
      color="secondary"
      variant="outlined"
      @click="login"
      text="Log in"
      block
    />

    <!-- Show the logout button if the user is authenticated -->
    <v-btn
      v-else
      color="secondary"
      variant="outlined"
      @click="logout"
      text="Log out"
      block
    />
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "AuthButtons",
  setup() {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const login = () => {
      loginWithRedirect();
    };

    const handleLogout = () => {
      logout({
        logoutParams: {
          returnTo: window.location.origin, // Redirects back to home after logout
        },
      });
    };

    return {
      isAuthenticated, // Reactive property that checks if the user is logged in
      login,
      logout: handleLogout, // Call the logout method
    };
  },
};
</script>
