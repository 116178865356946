import { auth0 } from '../auth0';
import axios from 'axios';
import { useLoadingState } from '../store/loadingState';
const { incrementLoading, decrementLoading } = useLoadingState();

const API_URL = process.env.VUE_APP_API_URL;


// Create Axios instance
const axiosInstance = axios.create();
// disable caching
axiosInstance.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-revalidate';
axiosInstance.defaults.headers.get['Pragma'] = 'no-cache';
axiosInstance.defaults.headers.get['Expires'] = '0';

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    incrementLoading(); // Increment loading counter when request starts
    return config;
  },
  function (error) {
    decrementLoading(); // Decrement on error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    decrementLoading(); // Decrement loading counter when request finishes
    return response;
  },
  function (error) {
    decrementLoading(); // Decrement on error
    return Promise.reject(error);
  }
);

async function getAccessTokenSilentlyOutsideComponent() {
  return auth0.getAccessTokenSilently();
}
async function setDefaultHeaders() {
  const token = await getAccessTokenSilentlyOutsideComponent();
  axiosInstance.defaults.headers.common['Authorization'] = token
    ? `Bearer ${token}`
    : '';
}


// Exported function for making a GET request
export default {
  async genericGet(endpoint, params = {}) {
    await setDefaultHeaders();
    const options = { params: params };
    const response = await axiosInstance.get(API_URL + endpoint, options);
    return response.data;
  },
  async genericPost(endpoint, body = {}) {
    await setDefaultHeaders();
    const response = await axiosInstance.post(API_URL + endpoint, body);
    return response.data;
  },
  async genericDelete(endpoint, id) {
    await setDefaultHeaders();
    const response = await axiosInstance.delete(API_URL + `${endpoint}/${id}`);
    return response.data;
  },
  async genericPut(endpoint, id, body) {
    await setDefaultHeaders();
    const response = await axiosInstance.put(API_URL + `${endpoint}/${id}`, body);
    return response.data;
  },
};
