<template>
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the Home page!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "HomePage"
  };
  </script>
  