<template>
  <v-container max-width="70vw">
    <!-- Form to Create Role -->
    <v-card class="mb-5">
      <v-card-title>Create Role</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createRole">
          <v-text-field
            v-model="form.role_name"
            label="Role Name"
            required
          ></v-text-field>
          <v-btn
            type="submit"
            color="primary"
            :loading="loadingState.isLoading"
            :disabled="!canCreateRole"
          >
            Create
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Data Table for Roles -->
    <v-card class="mb-5">
      <v-card-title>Roles</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="rolesHeaders"
          :items="roles"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-btn
              icon="mdi-delete"
              variant="outlined"
              @click="deleteRole(item.id)"
              :loading="loadingState.isLoading"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Select Existing Role -->
    <v-card class="mb-5">
      <v-card-title>Select Role</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedRole"
          :items="roles"
          item-title="name"
          item-value="id"
          label="Select Role"
          @update:modelValue="loadRoleDetails"
        ></v-select>
      </v-card-text>
    </v-card>

    <!-- Conditionally Show Role Menus -->
    <v-card v-if="selectedRole" class="pa-4">
      <v-card-title>{{ roleDetails ? roleDetails.name : "" }}</v-card-title>
      <v-row>
        <v-col cols="12">
          <!-- Add Activity Types -->
          <v-card class="mb-3 pa-2">
            <v-card-title>Add Activity Types</v-card-title>
            <v-select
              v-model="newActivityTypeId"
              :items="filteredActivityTypes"
              item-title="name"
              item-value="id"
              label="Select Activity Type"
            ></v-select>
            <v-btn
              @click="addActivityTypeForRole"
              :disabled="!newActivityTypeId"
              color="primary"
              :loading="loadingState.isLoading"
            >
              Add Activity Type
            </v-btn>

            <v-divider class="my-3 mx-0 px-0" />
            <!-- role activities table -->
            <v-data-table
              v-if="roleDetails && roleDetails.role_activity_types.length"
              :headers="activityHeaders"
              :items="roleDetails.role_activity_types"
              :sort-by="[{ key: 'activity_type_name', order: 'asc' }]"
            >
              <template #[`item.reference_label`]="{ item }">
                <v-text-field
                  class="pa-1"
                  variant="solo-inverted"
                  v-if="referenceRequired(item.activity_type_id)"
                  v-model="item.reference_label"
                  hide-details="auto"
                  @input="
                    updateRoleActivityTypeField(
                      item.id,
                      'reference_label',
                      item.reference_label
                    )
                  "
                ></v-text-field>
              </template>
              <template #[`item.references_specified`]="{ item }">
                <div v-if="referenceRequired(item.activity_type_id)">
                  <v-chip
                    variant="text"
                    v-if="!item.references || item.references.length < 1"
                    >X</v-chip
                  >
                  <v-chip variant="text" v-else>
                    {{ item.references.length }}</v-chip
                  >
                  <v-chip
                    variant="outlined"
                    color="secondary"
                    @click="openReferencesDialog(item)"
                    >...</v-chip
                  >
                </div>
              </template>
              <template #[`item.sort_rank`]="{ item }">
                <v-text-field
                  class="pa-1"
                  v-model="item.sort_rank"
                  hide-details="auto"
                  variant="solo-inverted"
                  @input="
                    updateRoleActivityTypeField(
                      item.id,
                      'sort_rank',
                      item.sort_rank
                    )
                  "
                  type="number"
                ></v-text-field>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-btn
                  icon="mdi-delete"
                  variant="outlined"
                  :loading="loadingState.isLoading"
                  @click="deleteActivityType(item.id)"
                />
              </template>
            </v-data-table>

            <p v-else class="pa-2">This role does not have activity types</p>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- references dialog -->
    <v-dialog v-model="showReferencesDialog" max-width="800px">
      <v-card class="pa-4">
        <v-card-title
          >References for {{ loadedRoleActivityType.activity_type_name }} on
          role {{ roleDetails ? roleDetails.name : "" }}</v-card-title
        >
        <v-card variant="outlined" class="pa-2">
          <v-data-table
            v-if="loadedRoleActivityType.references.length"
            :headers="referenceHeaders"
            :items="loadedRoleActivityType.references"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                icon="mdi-delete"
                variant="outlined"
                :loading="loadingState.isLoading"
                @click="deleteRATReference(item.id)"
              />
            </template>
          </v-data-table>
          <p v-else>
            Reference field will be free text as no references have been added
          </p>
        </v-card>
        <v-divider class="my-3 mx-0 px-0" />

        <v-form @submit.prevent="addReferenceToRoleActivityType">
          <v-text-field
            ref="referenceField"
            v-model="referenceField"
            label="reference"
          />
          <v-btn
            :disabled="!referenceField"
            type="submit"
            color="primary"
            :loading="loadingState.isLoading"
            >Add Reference</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Snackbar for Notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      top
      >{{ snackbar.message }}</v-snackbar
    >
  </v-container>
</template>
  
  <script>
import internalApi from "../apis/internal";
import { useLoadingState } from "../store/loadingState";
import { getHeaders } from "../helpers/commonFunctions";

export default {
  name: "RolePage",
  data() {
    return {
      form: {
        role_name: "",
      },
      search: "",
      roles: [],
      rolesHeaders: [],
      activityTypes: [],
      selectedRole: null,
      newActivityTypeId: null,
      roleDetails: null,

      activityHeaders: [
        { title: "Activity Type", value: "activity_type_name", sortable: true },
        { title: "Reference Label", value: "reference_label", sortable: true },
        {
          title: "References Specified",
          value: "references_specified",
          sortable: true,
        },
        { title: "Sort Rank", value: "sort_rank", sortable: true },
        { title: "", value: "actions" },
      ],

      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      loadingState: useLoadingState(),
      showReferencesDialog: false,
      referenceField: "",
      loadedRoleActivityType: null,
      referenceHeaders: [
        { title: "Reference", value: "reference", sortable: true },
        { title: "", value: "actions" },
      ],
    };
  },
  computed: {
    // Filter out activity types that have already been added for the role
    filteredActivityTypes() {
      if (!this.roleDetails || !this.roleDetails.role_activity_types)
        return this.activityTypes;
      const addedActivityIds = this.roleDetails.role_activity_types.map(
        (activity) => activity.activity_type_id
      );
      return this.activityTypes.filter(
        (type) => !addedActivityIds.includes(type.id)
      );
    },
    canCreateRole() {
      return this.form.role_name;
    },
  },
  created() {
    this.getRoles();
    this.getActivityTypes(); // Fetch all available activity types
  },
  methods: {
    getActivityTypeNameFromId(id) {
      let found = this.activityTypes.find((type) => type.id === id);
      return found ? found.name : id;
    },
    async deleteActivityType(id) {
      try {
        await internalApi.genericDelete("/RoleActivityTypes", id);
        this.roleDetails.role_activity_types =
          this.roleDetails.role_activity_types.filter((row) => row.id !== id);
        this.showSnackbar("Role activity type deleted");
      } catch (error) {
        this.showSnackbar("Error deleting entry" + error);
      }
    },
    async deleteRATReference(id) {
      try {
        await internalApi.genericDelete("/RoleActivityTypeReferences", id);
        this.loadedRoleActivityType.references =
          this.loadedRoleActivityType.references.filter((row) => row.id !== id);
        this.showSnackbar("reference deleted");
      } catch (error) {
        this.showSnackbar("Error deleting entry" + error);
      }
    },

    async updateRoleActivityTypeField(id, key, value) {
      try {
        await internalApi.genericPut(`RoleActivityTypes`, id, {
          [key]: value,
        });
        this.showSnackbar(`field updated`, "success");
      } catch (error) {
        this.showSnackbar(`Error updating role activity types`, "error");
      }
    },
    async deleteRole(id) {
      try {
        await internalApi.genericDelete("Roles", id);
        this.roles = this.roles.filter((role) => role.id !== id);
        this.showSnackbar(`Role deleted`, "success");
      } catch (error) {
        this.showSnackbar("Error deleting role", "error");
      }
    },
    // Fetch all roles
    async getRoles() {
      try {
        const response = await internalApi.genericGet("Roles");
        this.roles = response;
        this.rolesHeaders = getHeaders(response, [], true); // Adjust headers as necessary
      } catch (error) {
        this.showSnackbar("Error fetching roles", "error");
      }
    },
    // Fetch all activity types
    async getActivityTypes() {
      try {
        const types = await internalApi.genericGet("ActivityTypes");
        this.activityTypes = types.filter((type) => type.visible);
      } catch (error) {
        this.showSnackbar("Error fetching activity types", "error");
      }
    },
    getActivityTypeNamesFromIds(data) {
      if (!data || data.length < 1) return data;
      for (const row of data) {
        row.activity_type_name = this.getActivityTypeNameFromId(
          row.activity_type_id
        );
      }
      return data;
    },
    async addReferenceForRoleActivityType(roleATId, reference) {
      try {
        let response = await internalApi.genericPost(
          "RoleActivityTypeReferences",
          {
            role_activity_type_id: roleATId,
            reference: reference,
          }
        );
        this.loadedRoleActivityType.references.push(response);
        this.clearReferenceForm();
        this.showSnackbar("reference added successfully", "success");
      } catch (error) {
        this.showSnackbar("Error adding reference", "error");
      }
    },
    clearReferenceForm() {
      this.referenceField = "";
    },
    addReferenceToRoleActivityType() {
      if (!this.referenceField) return;
      if (!this.loadedRoleActivityType) return;
      this.addReferenceForRoleActivityType(
        this.loadedRoleActivityType.id,
        this.referenceField
      );
    },
    openReferencesDialog(item) {
      this.showReferencesDialog = true;
      this.loadedRoleActivityType = item;
    },
    async getActivityTypesReferences(data) {
      if (!data || data.length < 1) return data;
      for (const row of data) {
        const details = await this.getRoleActivityTypeDetails(row.id);
        row.references = details.role_activity_type_references;
        row.references_specified = row.references && row.references.length;
      }
      return data;
    },
    async getRoleActivityTypeDetails(id) {
      try {
        const response = await internalApi.genericGet(
          `RoleActivityTypes/${id}`,
          {
            associations: "role_activity_type_references,activity_type",
          }
        );
        return response;
      } catch (error) {
        this.showSnackbar("Error fetching role activity details", "error");
      }
    },
    referenceRequired(activityTypeId) {
      let found = this.activityTypes.find((el) => el.id === activityTypeId);
      if (!found) return false;
      return found.reference_one_required;
    },
    // Load role details with activity types
    async loadRoleDetails() {
      try {
        const response = await internalApi.genericGet(
          `Roles/${this.selectedRole}`,
          {
            associations: "role_activity_types",
          }
        );
        let roleDetails = response;
        roleDetails.role_activity_types = this.getActivityTypeNamesFromIds(
          roleDetails.role_activity_types
        );
        roleDetails.role_activity_types = await this.getActivityTypesReferences(
          roleDetails.role_activity_types
        );

        this.roleDetails = roleDetails;
      } catch (error) {
        this.showSnackbar("Error fetching role details", "error");
      }
    },
    // Create Role
    async createRole() {
      try {
        if (!this.canCreateRole) return;

        // Step 1: Create the role
        await internalApi.genericPost("Roles", {
          name: this.form.role_name,
        });

        // Reset form
        this.getRoles();
        this.resetForm();
        this.showSnackbar("Role created successfully", "success");
      } catch (error) {
        this.showSnackbar("Error creating role", "error");
      }
    },
    // Add Activity Type for the Selected Role
    async addActivityTypeForRole() {
      try {
        await internalApi.genericPost("RoleActivityTypes", {
          role_id: this.selectedRole,
          activity_type_id: this.newActivityTypeId,
          sort_rank: 0,
        });

        this.loadRoleDetails(); // Refresh activity types after adding
        this.newActivityTypeId = null; // Reset the activity type selection
        this.showSnackbar("Activity Type added successfully", "success");
      } catch (error) {
        this.showSnackbar("Error adding activity type", "error");
      }
    },
    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    // Reset the form to its default state
    resetForm() {
      this.form = {
        role_name: "",
      };
    },
  },
};
</script>
  
  <style scoped>
</style>
  