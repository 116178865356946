import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import { auth0 } from './auth0';
import VueSimpleAlert from "vue3-simple-alert-next";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'
import '@mdi/font/css/materialdesignicons.css';


let colorPallet = {
    first: '#264653',
    second: '#E76F51',
    third: '#E9C46A',
    forth: '#2A9D8F',
    fifth: '#F4A261',
}
const altColorPallet = {
    first: '#000000',
    second: '#00d2a2',
    third: '#f2eeeb',
    forth: '#FFFFFF',
    fifth: '#F4A261',
}   
colorPallet = altColorPallet
const vuetify = createVuetify({
    components,
    directives,
    blueprint: md3,
     icons: {
        defaultSet: 'mdi'
    },
    theme:{
        themes: {
        light: {
            colors:{
                primary: colorPallet.first,
                secondary: colorPallet.second,
                background: colorPallet.third,
                background2: colorPallet.forth,
            },
            variables: {
                'font-family': 'font Wix Madefor Display,Roboto, sans-serif', // Set your desired font
              },

         
        },
        dark:{
            colors:{
                primary: colorPallet.second,
                secondary: colorPallet.third,
                background: colorPallet.first,
                background2: colorPallet.forth,
            }
        
        }
    }
    }
})


const app = createApp(App);

app.use(router); // Use the router

app.use(vuetify)

app.use(auth0);

app.use(VueSimpleAlert);



app.mount('#app');
