<template>
    <v-select
      v-model="selectedCustomer"
      :items="customers"
      label="Customer"
      item-title="name" item-value="code"
     
      
    ></v-select>
  </template>
  
  <script>
import internalApi from '../apis/internal'; // Adjust path based on your project
  export default {
    name: 'CustomerSelect',
    props: {
      // The value passed from the parent component
      modelValue: {
        required: true
      }
    },
    data() {
      return {
        customers: [],
        selectedCustomer: this.modelValue // Start with the value passed from the parent
      };
    },
    watch: {
      modelValue(newVal) {
        this.selectedCustomer = newVal; // Keep selectedCustomer in sync with the prop from the parent
      },
      selectedCustomer(newVal){
        this.$emit('update:modelValue', newVal);
      }
    },
    created() {
      this.getCustomers(); // Load site data
    },
    methods: {
      async getCustomers() {
        try {
          const response = await internalApi.genericGet('/activities/customers');
          this.customers = response;
          this.customers.unshift({
            code: null,
            id: null,
            name: 'N/A'
          })
        } catch {
          this.$emit('showSnackbar', 'Error loading customers');
        }
      },
    }
  };
  </script>
  
  <style scoped></style>
  