<template>
  <v-app :theme="theme">
    <!-- App Bar for Navigation -->
    <NavBar @toggleTheme="toggleTheme" />
    
    <!-- Main content wrapped in a centered container -->
    <v-main>
      <v-container
        class="d-flex justify-center align-center"
        :style="{ minHeight: '70vh' }"
        :class="containerWidthClass"
      >
        <!-- Router View will display the component based on the current route -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import { ref, provide, computed } from 'vue';

export default {
  components: { NavBar },
  setup() {
    const theme = ref('light');
    provide('theme', theme);

    // Computed property to dynamically set container class based on screen size
    const containerWidthClass = computed(() => {
      return window.innerWidth <= 600 ? 'width-mobile' : 'width-desktop';
    });

    return {
      toggleTheme() {
        theme.value = theme.value === 'light' ? 'dark' : 'light';
      },
      theme,
      containerWidthClass,
    };
  },
};
</script>

<style scoped>
/* Desktop style */
.width-desktop {
  width: 80vw;
}

/* Mobile style */
.width-mobile {
  width: 98vw;
}

/* Adjust for other screen sizes if needed */
</style>
