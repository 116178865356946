// src/store/loadingState.js
import { reactive, computed } from 'vue';

const state = reactive({
  loadingCount: 0, // Track active requests
});

export function useLoadingState() {
  const isLoading = computed(() => state.loadingCount > 0); // True if any request is active

  return {
    state,
    isLoading,
    incrementLoading() {
      state.loadingCount++;
    },
    decrementLoading() {
      state.loadingCount--;
    },
  };
}
