export function getHeaders(data, filters = [], includeActions = false) {
  if (!data || data.length < 1) return [];

  let headers = Object.keys(data[0]).map((el) => {
    return { title: el.replace(/_/g, ' '), value: el, sortable: true };
  });
  if (filters && filters.length) {
    headers = headers.filter((header) => {
      return !filters.includes(header.value);
    });
  }
  if (includeActions) {
    headers.push({ title: '', value: 'actions' });
  }
  return headers;
}


export function formatDate(date, withTime, manipulateTimeZone = true) {
  if (!date) return '';
  if (withTime) {
    let formattedDate = date
    if(!(date instanceof  Date)){
      let dateString = date.substring(0, 19) + (manipulateTimeZone ? ':00Z' : '')
      formattedDate = new Date(dateString);
    }
    if(manipulateTimeZone) 
      return formattedDate.toLocaleString("en-GB", { timeZone: 'UTC' });
    return formattedDate.toLocaleString("en-GB");
  }
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}


export function getTodayDateStr() {
  const today = new Date();

  // Extract year, month, and day
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(today.getDate()).padStart(2, "0");

  // Format as yyyy-mm-dd
  return `${year}-${month}-${day}`;
}
