<template>
    <v-select
      v-model="selectedSite"
      :items="sites"
      label="Site"
      item-title="reference_one"
      return-object
      
    ></v-select>
  </template>
  
  <script>
import internalApi from '../apis/internal'; // Adjust path based on your project
  export default {
    name: 'SiteSelect',
    props: {
      // The value passed from the parent component
      modelValue: {
        required: true
      }
    },
    data() {
      return {
        sites: [],
        selectedSite: this.modelValue // Start with the value passed from the parent
      };
    },
    watch: {
      modelValue(newVal) {
        this.selectedSite = newVal; // Keep selectedSite in sync with the prop from the parent
      },
      selectedSite(newVal){
        this.$emit('update:modelValue', newVal);
      }
    },
    created() {
      this.getSites(); // Load site data
    },
    methods: {
      async getSites() {
        try {
          const response = await internalApi.genericGet('Sites');
          this.sites = response;
        } catch {
          this.$emit('showSnackbar', 'Error loading sites');
        }
      },
    }
  };
  </script>
  
  <style scoped></style>
  